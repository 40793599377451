
import RcDomainObject from "@/domain/common/RcDomainObject.js";
import Company from "@/domain/model/company/Company.js";
import InvoiceItemMap from "./InvoiceItemMap.js";
import RcDate from '@/domain/session/time/RcDate.js';

export default class Invoice extends RcDomainObject {
  
  static MODEL_NAME = "Invoice";
  
  constructor(domain, data) {
    super(domain, data, Invoice.MODEL_NAME);
  }
  
  static Fields = {
    COMPANY : Company.MODEL_NAME,
    ITEMS: "InvoiceItemMap",
    PDF_URL: "pdfUrl",
    YEAR: "year",
    MONTH: "month",
    NUMBER: "number",
    TAX_RATE: "taxRate",
    DISCOUNT: "discount",
    TOTAL_TAXES: "totalTaxes",
    TOTAL: "total",
    PAID: "paid",
    CREDIT: "credit",
    SENT_ON: "sentOn",
    SENT_ON_PAID: "sentOnPaid",
    PAID_DATE:"paidDate",
    OPERATIONS: "operations",
    PAYMENT_LINK: "paymentLink",
    PAYMENT_URL: "paymentUrl",
    PAYMENT_PRODUCT: "paymentProduct",
    INTEGRATION_REFERENCE: "integrationReference",
    CHARGE_ID: "chargeId",
    CHARGE_STATUS: "chargeStatus",
    TOTAL_PAYMENTS: "totalPayments",
    TOTAL_REMAINING: "totalRemaining"
  }
  
  fields() {
    return Invoice.FIELDS;
  }
  
  copy() {
    var data = this._copy();
    return new Invoice(this.domain, data);
  }
  
  company() {
    var companyData = this.data[Invoice.Fields.COMPANY];
    return new Company(this.domain, companyData);
  }
  
  items() {
    var data = this.data[Invoice.Fields.ITEMS];
    return new InvoiceItemMap(this.domain, data);
  }
  
  pdfUrl() {
    var host = this.domain.client().cdnHost;
    var url = host + this.getString(Invoice.Fields.PDF_URL);
    return url;
  }

  integrationReference() {
    return this.get(Invoice.Fields.INTEGRATION_REFERENCE);
  }
  
  year() {
    return this.get(Invoice.Fields.YEAR);
  }
  
  month() {
    return this.get(Invoice.Fields.MONTH);
  }
  
  number() {
    return this.getString(Invoice.Fields.NUMBER);
  }
  
  withNumber(number) {
    this.put(Invoice.Fields.NUMBER, number);
    return this;
  }
  
  taxRate() {
    var value = this.getString(Invoice.Fields.TAX_RATE);
    if (!value) {
      value = "0";
    }
    return value;
  }
  
  discount() {
    var value = this.getString(Invoice.Fields.DISCOUNT);
    if (!value) {
      value = "0";
    }
    return value;
  }
  
  totalTaxes() {
    return this.get(Invoice.Fields.TOTAL_TAXES);
  }
  
  credit() {
    var value = this.get(Invoice.Fields.CREDIT);
    if (!value) {
      value = 0;
    }
    return value;
  }
  
  total() {
    var total = this.get(Invoice.Fields.TOTAL);
    if (!total) {
      total = 0;
    }
    return total;
  }

  totalBeforeTaxes() {
    var total = this.total();
    var discount = this.discount();
    return total - discount;  
  }
  
  totalWithTaxes() {
    var total = this.total();
    var totalTaxes = this.totalTaxes();
    var credit = this.credit();
    var discount = this.discount();
    return total + totalTaxes - credit - discount;
  }

  totalPayments() {
    var total = this.get(Invoice.Fields.TOTAL_PAYMENTS);
    if (!total || total < 0) {
      total = 0;
    }

    return total 
  }

  isTotalRemainingZero() {
    var remaining = this.totalRemaining();
    return remaining <= 0;
  }
  
  totalRemaining() {
    var total = this.get(Invoice.Fields.TOTAL_REMAINING);
    if (!total || total < 0) {
      total = 0;
    }

    return total 
  }
  
  paid() {
    return this.getBoolean(Invoice.Fields.PAID);
  }
  
  withPaid() {
    this.put(Invoice.Fields.PAID, true);
    return this;
  }
  
  withNotPaid() {
    this.put(Invoice.Fields.PAID, false);
    return this;
  }
  
  paidDisplay() {
    return this.paid() ? "Yes!" : "No";
  }
  
  dueMonth() {
    var due = this.month() + 2;
    if (due > 11) {
      due = due - 11;
    }
    var month = "Jan";
    switch (due) {
      case 0 :
        month = "Jan";
        break;
      case 1 :
        month = "Feb";
        break;
      case 2 :
        month = "Mar";
        break;
      case 3 :
        month = "Apr";
        break;
      case 4 :
        month = "May";
        break;
      case 5 :
        month = "Jun";
        break;
      case 6 :
        month = "Jul";
        break;
      case 7 :
        month = "Aug";
        break;
      case 8 :
        month = "Sep";
        break;
      case 9 :
        month = "Oct";
        break;
      case 10 :
        month = "Nov";
        break;
      case 11 :
        month = "Dec";
        break;
    }
    return month;
  }
  
  dueDateDisplay() {
    var date = this.dueDate();
    return date.displayAs_YYYY_MM_DD();
  }

  dueDate() {
    var date = this.createdDateV2().plus(30);
    return date
  }

  paidDate() {
    var date = this.getInt(Invoice.Fields.PAID_DATE);
    return new RcDate(new Date(date), this.timeZone());
  }
  
  sentOn() {
    var date = this.getInt(Invoice.Fields.SENT_ON);
    return new RcDate(new Date(date), this.timeZone());
  }

  sentOnPaid() {
    var date = this.getInt(Invoice.Fields.SENT_ON_PAID);
    return new RcDate(new Date(date), this.timeZone());
  }

  operations() {
    return this.getString(Invoice.Fields.OPERATIONS);
  }
  
  paymentUrl() {
    return this.getString(Invoice.Fields.PAYMENT_URL);
  }
  
  chargeId() {
    return this.getString(Invoice.Fields.CHARGE_ID);
  }
  
  chargeStatus() {
    return this.getString(Invoice.Fields.CHARGE_STATUS);
  }
  
  paymentLink() {
    return this.getString(Invoice.Fields.PAYMENT_LINK);
  }
  
  paymentProduct() {
    return this.getString(Invoice.Fields.PAYMENT_PRODUCT);
  }
}